import axios from 'axios';
export default class ManagerService {

    // async getManagerList(name,email,mobile,country_id,status) {
    //     return await axios
    //         .post(`asnhist-dev/api/v1/admin/manager`, {
    //             // title: title,
    //             name: name,
    //             email: email,
    //             mobile: mobile,
    //             country_id: country_id,
    //             status: status,
    //         })
    //         .then((res) => res);
    // }
    async getManagerList(data) {
        return await axios.post('asnhist-dev/api/v1/admin/manager', data);
    }

    async getCountrydropdown() {
        return await axios
            .get(`asnhist-dev/api/v1/admin/country`, {
                
            })
            .then((res) => res);
    }
    async getCountryCodedropdown() {
        return await axios
            .get(`asnhist-dev/api/v1/admin/country/code`, {
                
            })
            .then((res) => res);
    }

    async viewManagerManagement(ids) {
        return await axios.post(`asnhist-dev/api/v1/admin/manager/id`, { id: ids }).then((res) => res);
    }

    
}