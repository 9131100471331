
// import validator from 'validator';

const validateCreateFreeBoard = (data) => {
    let errors = {};

    const { title, description,category_id,country_id} = data;

    if (!title) {
        errors.title = '제목은 필수 항목입니다.';
    }
    if (!description) {
        errors.description = '내용은 필수 항목입니다.';
    }
    
    if (!category_id) {
        errors.category_id = '카테고리를 선택하세요.';
    }
    if (!country_id) {
        errors.country_id = '국가를 선택하세요.';
    }
    // if (validator.isEmpty(file)) {
    //     errors.file = 'please upload the file';
    // }
    return {
        isInvalid: Object.keys(errors).length > 0,
        error: errors,
    };
};

export default validateCreateFreeBoard;